
import { Options, Vue } from 'vue-class-component'
import axios from '@/plugins/axios'
import { ElMessageBox } from 'element-plus'
import MappingPage from '../components/Mapping.vue'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import ShipmentMapping from '../components/ShipmentMapping.vue'
import listConnectAvailable from '../../../store/connect-available'
import ConnectWithOauth from '../components/ConnectWithOauth.vue'
import { ElMessage } from 'element-plus'
import MiraklButton from '@/components/MiraklButton.vue';
interface Res {
  list: []
  total: number
}
interface Response {
  status: boolean
  has_confirmation_url: boolean
  message: string
  confirmation_url: string
}
@Options({
  components: {
    MappingPage,
    FontAwesomeIcon,
    ShipmentMapping,
    ConnectWithOauth,
    MiraklButton,
  }
})
export default class ConnectionPage extends Vue {
  titlePage = 'Connection'
  titleModalConnect = ''
  headers = {
    'token-user': localStorage.getItem('tokenUser'),
    account: localStorage.getItem('account')
  }
  url = `${process.env.VUE_APP_BASE_URL}/`
  account = localStorage.getItem('account')
  connectData: any = []
  platformData: any = []
  dataConnect: any = {}
  applicationIds: any = {}
  callbacks: any = {}
  listCronDelete = [{}]
  listConnectionExpiryDate = [{ _id: '' }]
  listScript = [
    {
      connectFrom: { _id: '' },
      connectTo: { _id: '' },
      expiried: false,
      status: false
    }
  ]
  dialogAddConnect = false
  connectFrom = ''
  disabledSelect = false
  options = []
  idConnect = ''
  typeMapping = ''
  isMapping = false
  eventUnregisterers: any = []
  connectAvailable: any = []
  connectExist: any = []
  showListTarget = false
  visible = false
  updateAccessToken = false
  dataShopify: any
  dataShopifyFrom: any
  newConnectionId: any
  discountlv1: any
  discountlv2: any
  objDiv: any
  listPrice: any
  accountInfo: any
  accountSelected: any
  isShowOauth = false
  urlOauth = ''
  M2HMAC = ['HMAC-SHA256', 'HMAC-SHA1']
  tokenTypes = [
    //woocommerce
    'consumerKey',
    'consumerSecret',

    //CA

    // bigcommerce    
    'storeHash',
    'bigcommerceToken',
    'clientId',

    // quickbook

    // Veeqo
    'apikey', // linnworks
    
    // Magento 2
    'magentoConsumerKey',
    'magentoConsumerSecret',
    'magento_access_token',
    'magentoTokenSecret',

    // Magento 1
    'magento1_key',
    'magento1_secret',

    // Teapplix
    'teapplix_api_token',

    // Mirakl
    'mirakl_client_id',
    'mirakl_client_secret',
    'mirakl_company_id',

    // Shopify

    // peoplevox
    'peoplevoxPassword',

    // prestashop
    'prestashopKey',

    // visualsoft
    'visualsoftPassword',

    // lightspeed
    'lightspeedClientSecret',

    // lightspeedX
    'lightspeedX_token',

    // Tiktok

    // alphabroder
    'alphabroderPassword',
    'alphabroderFTPPassword',
    'alphabroderPublicPassword',

    // brightpearl

    // Odoo
    'odoo_password',

    // Essendant
    'essendantPassword',

    // Xentral
    'xentralToken',

    // NetSuite
    'netsuiteConsumerKey',
    'netsuiteConsumerSecret',
    'netsuiteTokenId',
    'netsuiteTokenSecret',

    // Xero
    'xero_client_secret',

    // Apprise
    'apprisePassword',

    // Portica

    // Active Ant
    'activeant_password',

    // Aims360
    'aims360_password',
    'aims360_client_secret',

    // Cin7
    'cin7_application_key',

    // SapB1
    'sapb1_password',
    'sapb1_company_db',

    // Dynamics365
    // 'dynamics365_org_name',
    'dynamics365_tenant_id',
    'dynamics365_client_id',
    'dynamics365_client_secret',
    'dynamics365_companies_id',

    // Quickbook
    'quickbookPassword',

    // Convictional
    'convictionalPassword',
    'convictionalToken',

    //Warner
    'warner_password',
    'warner_port',

    //Quivo
    'quivo_password',
    'quivo_api_key',

    // CommerceHub
    'commercehub_client_id',
    'commercehub_client_secret',

    // LinkWeld
    'linkweld_connect_string',

    // myflex
    'myflex_url',
    'myflex_access_token',
    'myflex_refresh_token',

    //dynamicax
    'dynamicsAXPassword',

    // dynamicsfo
    'dynamicsfo_tenant_id',
    'dynamicsfo_client_id',
    'dynamicsfo_client_secret',
    'dynamicsfo_resource',
    'dynamicsfo_area_id',

    //SapB1 File Export
    'sapb1fileexport_password',
    'sapb1fileexport_port',

    //Channel Advisor File Import
    'cafileimport_password',
    // CartCom
    'cartcomId',
    'cartcomToken',
  ]
  isShowConnectionPopup = false
  selectedTargetConnection: any = {}


  async created() {
    this.accountSelected = await this.$store.state.auth.accountSelected
    await this.getListConnect()
    await this.getListScript()
    await this.getListPlatform()
    const hasRefreshToken = this.$route.query.hasRefreshToken
    const isError = this.$route.query.isError
    const message = this.$route.query.message
    const isNew = this.$route.query.isNew
    const locationId = this.$route.query.locationId
    const shopify_shopName = this.$route.query.shopify_shopName
    const connectionId = this.$route.query.connectionId
    const type = this.$route.query.type

    if (hasRefreshToken && isError && message) {
      if (isError === 'true') {
        this.$message.warning('Update token fail !!!')
      }
      if (isError === 'false') {
        this.$message.success(message)
      }
      this.$router.replace({ query: {} })
    }
    if (
      type === 'shopify' &&
      isNew === 'true' &&
      !this.accountSelected.isFreeAccount
    ) {
      this.$store.dispatch('setLoading', true, { root: true })
      let param = {
        id: connectionId,
        shopify_shopName: shopify_shopName,
        locationId: locationId
      }
      let res: Response = await axios.get(
        'user/connect/shopify-comfirm-subscription',
        {
          headers: this.headers,
          params: JSON.stringify(param)
        }
      )
      if (res.status && res.has_confirmation_url) {
        window.location.href = res.confirmation_url
      } else {
        this.$message.warning(res.message)
        this.$store.dispatch('setLoading', false, { root: true })
      }
    }
  }

  async toggleConnection(newValue: boolean, connection: any) {
    this.selectedTargetConnection = connection
    if (!newValue) {
      this.isShowConnectionPopup = true
    } else {
     await this.handleSaveToggleConnection(true)
    }
  }

  async handleSaveToggleConnection(isEnable: boolean) {
    await this.$store.dispatch('setLoading', true, { root: true })
    const payload = {
      isEnable,
      connectionId: this.selectedTargetConnection._id
    }

    const res = await axios.post('user/connect/toggle-connection', payload, {
      headers: this.headers,
    }).then((response) => {
        if (response.status) {
          ElMessage.success('Update Status successfully !!!')
          this.$store.dispatch('reRender')
        } else {
          ElMessage.error('Something wrong !!!')
        }
      })
    await this.$store.dispatch('setLoading', false, { root: true })
  }

  handleCancelToggleConnection() {
    this.isShowConnectionPopup = false
    this.selectedTargetConnection.is_active = true
  }

  async getListConnect() {
    await this.$store.dispatch('setLoading', true, { root: true })
    let res: any = {}
    res = await axios.get('user/connect/list', {
      headers: this.headers
    })
    if (res.data) {
      console.log(`getListConnect res: ${JSON.stringify(res.data)}`)

      this.connectData = res.data
      for (let connect of this.connectData) {
        if (connect.connectFrom) {
          this.connectFrom = connect.type
        } else this.connectExist.push(connect.type)
        if (connect.connectFrom && connect.type == 'shopify') {
          this.dataShopifyFrom = connect
        }
      }
      this.applicationIds = res.applicationIds
      this.callbacks = res.callbacks
    }
    await this.$store.dispatch('setLoading', false, { root: true })
  }

  async getListScript(page = 0) {
    let res: Res = {
      list: [],
      total: 0
    }
    await this.$store.dispatch('setLoading', true, { root: true })
    res = await axios.get('user/setting-script', {
      headers: this.headers,
      params: {
        account: localStorage.getItem('account'),
        page: page,
        limit: 100
      }
    })
    if (res) {
      this.listScript = res.list
    }
    await this.$store.dispatch('setLoading', false, { root: true })
  }

  async getListPlatform() {
    let res: any = {}
    res = await axios.get('admin/platform', {
      headers: this.headers
    })
    if (res) {
      this.platformData = res.data
      listConnectAvailable.forEach((connect, i) => {
        if (connect.hub.toLowerCase() == this.connectFrom.toLowerCase()) {
          this.connectAvailable.push(connect.target)
        }
      })

      this.platformData.forEach((platform: any = {}, i: number) => {
        if (platform.type == this.connectFrom) {
          this.listPrice = platform.listPrice
          this.discountlv1 = platform.discountlv1
          this.discountlv2 = platform.discountlv2
        }
        if (this.connectAvailable.includes(platform.type)) {
          platform.available = true
        } else platform.available = false
      })
      if (this.listPrice.length > 0) {
        this.platformData.forEach((platform: any = {}, i: number) => {
          this.listPrice.forEach((itemPrice: any = {}) => {
            if (
              itemPrice.target.toLowerCase() === platform.type.toLowerCase()
            ) {
              if (this.connectExist.includes(platform.type)) {
                if (
                  this.connectExist.filter((x: any) => x === platform.type)
                    .length <= 2
                ) {
                  platform.price = (
                    (itemPrice.price * (100 - this.discountlv1)) /
                    100
                  ).toFixed(2)
                  platform.discount = this.discountlv1
                }
                if (
                  this.connectExist.filter((x: any) => x === platform.type)
                    .length >= 3
                ) {
                  platform.price = (
                    (itemPrice.price * (100 - this.discountlv2)) /
                    100
                  ).toFixed(2)
                  platform.discount = this.discountlv2
                }
              } else platform.price = itemPrice.price
              if (this.accountSelected.isFreeAccount) {
                platform.price = 0
              }
            }
          })
        })
      }
    }
  }

  async handleOpenConnect(connect: any) {
    this.dataConnect = {}
    if (connect) {
      this.titleModalConnect = `${connect.name} connection`
      let data = this.platformData.filter(
        (platform: any = {}) => platform._id == connect.platform._id
      )
      this.options = data[0].options
      for (let item of data[0].options) {
        this.dataConnect[item.key] = connect[item.key]
      }
      this.dataConnect.name = connect.name
      this.dataConnect.type = connect.type
      this.dataConnect.id = connect._id
      this.dataConnect.connectFrom = connect.connectFrom
      this.dataConnect.connectTo = connect.connectTo
      this.disabledSelect = true
      if (connect.type === 'tiktok') {
        let tiktokShops = await axios.post(
          'user/connect/tiktok/get-shops',
          {
            connectionId: connect._id
          },
          {
            headers: this.headers
          }
        )
        console.log('tiktokShops', tiktokShops)
      }
      if (
        ['magento2'].includes(this.dataConnect.type) &&
        !this.dataConnect.magentoSignatureMethod
      ) {
        this.dataConnect.magentoSignatureMethod = 'HMAC-SHA256'
      }
      if (
        this.dataConnect.type === 'magento2' &&
        !this.dataConnect.magentoVersion
      ) {
        this.dataConnect.magentoVersion = 'old'
      }
    } else {
      this.titleModalConnect = `Add new connection`
      this.dataConnect.connectFrom = false
      this.dataConnect.connectTo = true
      this.disabledSelect = false
    }
    this.dialogAddConnect = true
  }

  handleOption() {
    for (let platform of this.platformData) {
      if (platform.type === this.dataConnect.type) {
        this.dataConnect.platform = platform._id
        this.dataConnect.price = platform.price
        this.dataConnect.discount = platform.discount
        this.options = platform.options
        for (let item of platform.options) {
          this.dataConnect[item.key] = ''
        }
      }
    }
  }

  async handleSave() {
    const refFrorm = this?.$refs['refForm'] as HTMLFormElement
    const isValidate = await refFrorm.validate()
    if (!isValidate) return

    await this.$store.dispatch('setLoading', true, { root: true })
    let res: any
    if (this.dataConnect.id) {
      res = await axios.put('user/connect', this.dataConnect, {
        headers: this.headers
      })
    } else {
      res = await axios.post('user/connect', this.dataConnect, {
        headers: this.headers
      })
    }

    if (res.status) {
      if (this.dataConnect.id) {
        this.$message.success(res.message)
        this.dialogAddConnect = false
        this.showListTarget = false
        return await this.getListConnect()
      }

      this.newConnectionId = res.data._id
      if (res.data && res.data.type == 'shopify') {
        this.dataShopify = res.data
      }
      if (res.data.type == 'shopify') {
        this.dialogAddConnect = false
        this.showListTarget = false
        await this.handleUpdateTokenAndRedirect(res.data)
        this.connectExist = []
        await this.getListConnect()
      } else {
        this.$message.success(res.message)
        this.dialogAddConnect = false
        this.showListTarget = false
        this.connectExist = []
        await this.getListConnect()
      }
      if (
        this.connectFrom == 'shopify' &&
        !this.accountSelected.isFreeAccount
      ) {
        await this.$store.dispatch('setLoading', true, { root: true })
        let param = {
          id: this.newConnectionId,
          shopify_shopName: this.dataShopifyFrom.shopify_shopName,
          locationId: this.dataShopifyFrom.locationId,
          accessToken: this.dataShopifyFrom.tokenObject.accessToken
        }
        let res: Response = await axios.get(
          'user/connect/shopify-comfirm-subscription',
          {
            headers: this.headers,
            params: JSON.stringify(param)
          }
        )
        if (res.status && res.has_confirmation_url) {
          window.location.href = res.confirmation_url
        } else {
          this.$message.warning(res.message)
          await this.$store.dispatch('setLoading', false, { root: true })
        }
      }
    } else {
      this.$message.warning(res.message)
    }
    this.showListTarget = false
    await this.$store.dispatch('setLoading', false, { root: true })
  }

  async handleDelete(id: any, type: any) {
    let res: any
    ElMessageBox.confirm('Do you want to delete?', 'Warning', {
      confirmButtonText: 'OK',
      cancelButtonText: 'Cancel',
      type: 'warning'
    }).then(async () => {
      this.listScript.forEach((cron) => {
        if (id === cron.connectFrom._id || id === cron.connectTo._id) {
          this.listCronDelete.push(cron)
        }
      })
      this.listCronDelete.shift()
      if (this.listCronDelete.length > 0) {
        this.$store.dispatch('setLoading', true, { root: true })
        await axios.put(
          'user/setting-script/delete-many',
          this.listCronDelete,
          {
            headers: this.headers,
            params: {
              account: localStorage.getItem('account')
            }
          }
        )
      }
      res = await axios.delete('/user/connect', {
        params: { id: id },
        headers: this.headers
      })
      if (res.status) {
        this.$message.success(res.message)
        this.listCronDelete = [{}]
        this.connectExist = []
        this.showListTarget = false
        await this.getListConnect()
        if (this.connectFrom == 'shopify') {
          this.$store.dispatch('setLoading', true, { root: true })
          let param = {
            shopify_shopName: this.dataShopifyFrom.shopify_shopName,
            locationId: this.dataShopifyFrom.locationId,
            accessToken: this.dataShopifyFrom.tokenObject.accessToken
          }
          let res: Response = await axios.get(
            'user/connect/remove-shopify-connect',
            {
              headers: this.headers,
              params: JSON.stringify(param)
            }
          )
          if (res.status && res.has_confirmation_url) {
            window.location.href = res.confirmation_url
          } else {
            this.$message.warning(res.message)
            this.$store.dispatch('setLoading', false, { root: true })
          }
        }
      } else {
        this.$message.warning(res.message)
        this.$store.dispatch('setLoading', false, { root: true })
      }
    })
  }

  handleMapping(id: string, type: string) {
    this.isMapping = false
    if (id && type) {
      this.idConnect = id
      this.typeMapping = type
      this.isMapping = true
    }
  }

  closeModalMapping() {
    this.isMapping = false
  }

  async handleUpdateToken(connection: any) {
    await this.$store.dispatch('setLoading', true, { root: true })
    let query: any
    let urlPath: any = null
    if (connection.type == 'shopify') {
      query = this.encodeQueryData({
        client_id: this.applicationIds.shopify,
        scope:
          'write_payment_terms,read_publications,write_publications,read_products,write_products,read_orders,write_orders,read_all_orders,read_inventory,write_inventory,read_draft_orders,write_draft_orders,read_merchant_managed_fulfillment_orders,write_merchant_managed_fulfillment_orders,read_assigned_fulfillment_orders,write_assigned_fulfillment_orders,read_third_party_fulfillment_orders,write_third_party_fulfillment_orders,read_shipping,write_shipping,read_fulfillments,write_fulfillments,read_returns,write_returns,write_customers',
        redirect_uri: this.callbacks.shopify,
        state: JSON.stringify({
          tokenUser: localStorage.getItem('tokenUser'),
          accountId: localStorage.getItem('account'),
          connectionId: connection._id,
          currentPage: window.location.href
        })
      })
      urlPath =
        'https://' +
        connection.shopify_shopName +
        '.myshopify.com/admin/oauth/authorize?' +
        query
    }
    if (connection.type == 'channelAdvisor') {
      query = this.encodeQueryData({
        client_id: this.applicationIds.channelAdvisor,
        response_type: 'code',
        access_type: 'offline',
        scope: 'orders inventory',
        approval_prompt:
          connection.tokenObject && connection.tokenObject.hasRefreshToken
            ? 'auto'
            : 'force',
        redirect_uri: this.callbacks.channelAdvisor,
        state: JSON.stringify({
          tokenUser: localStorage.getItem('tokenUser'),
          accountId: localStorage.getItem('account'),
          connectionId: connection._id,
          currentPage: window.location.href
        })
      })
      urlPath = 'https://api.channeladvisor.com/oauth2/authorize?' + query
    }
    if (connection.type == 'xero') {
      query = this.encodeQueryData({
        response_type: 'code',
        client_id: connection.xero_client_id,
        redirect_uri: this.callbacks.xero,
        scope:
          'accounting.transactions offline_access accounting.contacts accounting.settings',
        state: JSON.stringify({
          tokenUser: localStorage.getItem('tokenUser'),
          accountId: localStorage.getItem('account'),
          connectionId: connection._id,
          currentPage: window.location.href
        })
      })
      urlPath = 'https://login.xero.com/identity/connect/authorize?' + query
    }
    if (connection.type === 'esty') {
      query = this.encodeQueryData({
        client_id: this.applicationIds.esty.user,
        response_type: 'code',
        code_challenge: this.applicationIds.esty.codeChallenge,
        code_challenge_method: this.applicationIds.esty.codeChallengeMethod,
        scope: this.applicationIds.esty.scopes.join(' '),

        redirect_uri: this.callbacks.esty,
        state: JSON.stringify({
          tokenUser: localStorage.getItem('tokenUser'),
          accountId: localStorage.getItem('account'),
          connectionId: connection._id,
          currentPage: window.location.href,
          codeVerifier: this.applicationIds.esty.codeVerifier
        })
      })
      urlPath = 'https://www.etsy.com/oauth/connect?' + query
    }
    if (connection.type === 'quickbook') {
      urlPath = this.applicationIds.quickbook.urlAuth
      if (urlPath) {
        urlPath = urlPath
          .replace(
            'tokenUser_iomnic',
            encodeURIComponent(localStorage.getItem('tokenUser') || '')
          )
          .replace('accountId_iomnic', localStorage.getItem('account'))
          .replace('connectionId_iomnic', connection._id)
          .replace(
            'currentPage_iomnic',
            encodeURIComponent(window.location.href)
          )
      }
    }
    if (connection.type === 'tiktok') {
      query = this.encodeQueryData({
        app_key: this.applicationIds.tiktok,
        state: JSON.stringify({
          tokenUser: localStorage.getItem('tokenUser'),
          accountId: localStorage.getItem('account'),
          connectionId: connection._id,
          currentPage: window.location.href
        })
      })
      urlPath = 'https://auth.tiktok-shops.com/oauth/auhorize?' + query
    }
    if (connection.type === 'brightpearl') {
      query = this.encodeQueryData({
        response_type: 'code',
        client_id: this.applicationIds.brightpearl.clientId,
        redirect_uri: this.applicationIds.brightpearl.redirectUri,
        state: connection._id,
      })
      urlPath =
        `https://oauth.brightpearl.com/authorize/${connection.brightpearlAccountId}?` +
        query
    }
    if (connection.type === 'mirakl') {
      query = this.encodeQueryData({
        client_id: this.applicationIds.mirakl.clientId,
        response_type: 'code',
        redirect_uri: this.callbacks.mirakl,
        state: this.generateRandomString(30)
      });
      urlPath = `${this.applicationIds.mirakl.url}authorize?${query}`;
    }
    console.log(urlPath)
    if (urlPath != null) {
      window.open(urlPath, '_self', '')
      window.close()
    }
    // if (connection.type === "shopify") {
    //   window.location.href = urlPath;
    //   return;
    // }
    // this.$nextTick(async () => {
    //   let oauthState: any = await this.showAuthWindow({
    //     path: urlPath,
    //   });
    //   this.$store.dispatch("setLoading", false, { root: true });
    //   if (oauthState) {
    //     this.$message.success(oauthState.message);
    //   } else {
    //     this.$message.warning("Update token fail !!!");
    //   }
    //   this.isShowOauth = false;
    //   this.urlOauth = '';
    // });
  }

  async handleDownloadFile(connection: any) {
    function toGUID(id: string) {
      const hex = id.padStart(32, '0').toUpperCase();
      return [
        hex.slice(0, 8),
        hex.slice(8, 12),
        hex.slice(12, 16),
        hex.slice(16, 20),
        hex.slice(20, 32)
      ].join('-');
    }
    // ?account=${connection.account}&connection=${connection._id} .replace('dev.iomnic.com', 'wiserobot:d3v3l0p@dev.iomnic.com')
    const appUrl = `${process.env.VUE_APP_BASE_URL}/quickbook/quickbook-desktop/${connection._id}/wsdl`;
    let runEveryNMinutes = connection.quickbookRunEveryNMinutes << 0;
    if ( runEveryNMinutes <= 0) {
      runEveryNMinutes = 15;
    }
    const toXml = `<?xml version="1.0"?>
<QBWCXML>
  <AppName>iOmniC</AppName>
  <AppID></AppID>
  <AppURL>${appUrl}</AppURL>
  <AppSupport>${process.env.VUE_APP_DOMAIN}</AppSupport>
  <AppDescription></AppDescription>
  <UserName>${connection.quickbookUserName}</UserName>
  <OwnerID>{${toGUID(`${connection.account}${connection._id}`)}}</OwnerID>
  <FileID>{${toGUID(`${connection._id}${connection.account}`)}}</FileID>
  <QBType>QBFS</QBType>
  <Scheduler>
    <RunEveryNMinutes>${runEveryNMinutes}</RunEveryNMinutes>
  </Scheduler>
  <IsReadOnly>false</IsReadOnly>
</QBWCXML>`;

    const filename = "qbc.qwc";
    const pseudoelement = document.createElement("a");
    const blob = new Blob([toXml ], { type: "application/xml" });

    pseudoelement.setAttribute("href", window.URL.createObjectURL(blob));
    pseudoelement.setAttribute("download", filename);

    pseudoelement.dataset.downloadurl = ["application/xml", pseudoelement.download, pseudoelement.href].join(":");
    pseudoelement.draggable = true;
    pseudoelement.classList.add("dragout");
    pseudoelement.click();
  }

  async handleUpdateTokenAndRedirect(connection: any) {
    await this.$store.dispatch('setLoading', true, { root: true })
    let query: any
    let urlPath: any = null
    if (connection.type == 'shopify') {
      query = this.encodeQueryData({
        client_id: this.applicationIds.shopify,
        scope:
          'write_payment_terms,read_publications,write_publications,read_products,write_products,read_orders,write_orders,read_all_orders,read_inventory,write_inventory,read_draft_orders,write_draft_orders,read_merchant_managed_fulfillment_orders,write_merchant_managed_fulfillment_orders,read_assigned_fulfillment_orders,write_assigned_fulfillment_orders,read_third_party_fulfillment_orders,write_third_party_fulfillment_orders,read_shipping,write_shipping,read_fulfillments,write_fulfillments,read_returns,write_returns',
        redirect_uri: this.callbacks.shopify,
        state: JSON.stringify({
          tokenUser: localStorage.getItem('tokenUser'),
          accountId: localStorage.getItem('account'),
          connectionId: connection._id,
          isNew: true,
          currentPage: window.location.href
        })
      })
      urlPath =
        'https://' +
        connection.shopify_shopName +
        '.myshopify.com/admin/oauth/authorize?' +
        query
    }
    if (connection.type === 'shopify') {
      console.log(urlPath)
      window.location.href = urlPath
      return
    }
  }

  showAuthWindow(options: {
    path: any
    windowName?: any
    windowOptions?: any
  }) {
    return new Promise((resolve, reject) => {
      // this.isShowOauth = true;
      // this.urlOauth = options.path;

      options.windowName = options.windowName || 'ConnectWithOAuth'
      options.windowOptions =
        options.windowOptions || 'location=0,status=0,width=800,height=600'
      var _oauthWindow: any = open(
        options.path,
        options.windowName,
        options.windowOptions
      )
      _oauthWindow.focus()
      var oauthState = {}

      function onPopupSendMessage(event: any) {
        if (event.data.event === 'oauthState') {
          oauthState = event.data
        }
      }

      window.addEventListener('message', onPopupSendMessage)

      var _oauthInterval = setInterval(function () {
        if (!_oauthWindow.closed) return
        clearInterval(_oauthInterval)
        resolve(oauthState)
        window.removeEventListener('message', onPopupSendMessage)
      }, 1000)
    })
  }
  encodeQueryData(data: any) {
    let ret = []
    for (let d in data)
      ret.push(encodeURIComponent(d) + '=' + encodeURIComponent(data[d]))
    return ret.join('&')
  }
  generateRandomString(length: number) {
    let result = '';
      const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
      const charactersLength = characters.length;
      for (let i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
      }
      return result;
  }
  selectToPlatform(platform: any) {
    this.dataConnect = {}
    this.titleModalConnect = `${platform.name} connection`

    this.options = platform.options
    for (let item of platform.options) {
      this.dataConnect[item.key] = platform[item.key]
    }
    this.dataConnect.type = platform.type
    this.dataConnect.platform = platform._id
    this.dataConnect.connectFrom = false
    this.dataConnect.connectTo = true
    this.dialogAddConnect = true
    this.handleOption()
  }

  async handleAddNewTargetConnect() {
    this.showListTarget = !this.showListTarget
    await this.getListPlatform()
  }

  truncate(string: any) {
    if (string.length > 20) return string.substring(0, 20) + '...'
    else return string
  }
}
